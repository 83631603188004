import React from 'react'
import './sixlogos.css'

const SixLogos = () => (
    <div className='six-logos-start'> 
    <div className='six-logos1'>
    <a href='https://www.sompo-intl.com/insurance/' target="_blank" rel="noreferrer"> <img src="img/LogosRe1.jpg" alt="Sompo Internacional"/></a>
    </div>
    <div className='six-logos2'>
    <a href='https://www.libertymutualre.com/' target="_blank" rel="noreferrer"> <img src="img/LogosRe2.jpg" alt="Liberty Mutual"/></a>
    </div> 
    <div className='six-logos3'>
    <a href='https://www.australre.com/es/' target="_blank" rel="noreferrer"><img src="img/LogosRe3.jpg" alt="Austral re"/></a>
    </div>
    <div className='six-logos4'>
    <a href='https://www.axiscapital.com/' target="_blank" rel="noreferrer"><img src="img/LogosRe4.jpg" alt="Axis"/></a>
    </div>
    <div className='six-logos5'>
    <a href='https://www.aig.com/about-us/validus-re' target="_blank" rel="noreferrer"><img src="img/LogosRe5.jpg" alt="Validus re"/></a>
    </div>
    <div className='six-logos6'>
    <a href='https://group.atradius.com/' target="_blank" rel="noreferrer"><img src="img/LogosRe6.jpg" alt="Atradius"/></a>
    </div>
    <div className='six-logos7'>
    <a href='https://www.coface.com/' target="_blank" rel="noreferrer"><img src="img/LogosRe7.jpg" alt="Coface"/></a>
    </div>
    <div className='six-logos8'>
    <a href='https://credendo.com/en' target="_blank" rel="noreferrer"><img src="img/LogosRe8.jpg" alt="Credendo"/></a>
    </div>
    <div className='six-logos9'>
    <a href='https://www.hamiltongroup.com/' target="_blank" rel="noreferrer"><img src="img/LogosRe9.jpg" alt="Hamilton"/></a>
    </div>
    <div className='six-logos10'>
    <a href='https://www.acrargentina.com.ar/' target="_blank" rel="noreferrer"><img src="img/LogosRe10.jpg" alt="ACR Argentina"/></a>
    </div>
    <div className='six-logos11'>
    <a href='https://www.beazley.com/es-419' target="_blank" rel="noreferrer"><img src="img/LogosRe11.jpg" alt="Beazley"/></a>
    </div>
   
  </div>
  )

export default SixLogos