import React from 'react'
import BigButton from '../utils/BigButton/BigButton'
import { isMobileOnly } from 'react-device-detect'

const CotizadorDirectoresQuestion = ({handleSubmitData, amount, handleChangeDirectorData, errors, formText, explain }) => {
  return (
    <form className='first-form' onSubmit={handleSubmitData}>
      <p className='explain'>{explain}</p>
      <p className='input-explain'>{formText.explain}</p>
      <p className='rent-label'>{formText.socialCapitalLabel}</p>
      <div className='input-box'>
          <i className='i'>$</i>
          <input name="customAmount" className="light-input-rent" value={amount.customAmount} type="number" onChange={e => handleChangeDirectorData(e, 'customAmount', 'number', true, 15, 5)} required={true} />
          {errors['customAmount'] && <p className='error-white'>{errors['customAmount']}</p>}
      </div>
    <p className='rent-label'>{formText.directorQuantityLabel}</p>
    <div className='input-box'>
        <input name="quantity" className="light-input-rent" value={amount.quantity} type="number" onChange={e => handleChangeDirectorData(e, 'quantity', 'number', true, 2, 0)} required={true} />
         {errors['quantity'] && <p className='error-white'>{errors['quantity']}</p>}
    </div>
    <div className='button-send'>
    <BigButton type="submit" name= {formText.quoteButton} className={ isMobileOnly? "contact-dark-button" :"rent-button"}/>
    </div>
</form>
  )
}

export default CotizadorDirectoresQuestion