import React from 'react'
import ProductMenu from '../utils/ProductMenu/ProductMenu'
import './polizadigital.css'
import { useLangContext } from '../../Context/LangContext';
import productTexts from '../utils/Texts/productText.json'
import digitalSurety from '.././utils/Texts/digitalSurety.json'
import { useEffect } from 'react'
import SecondPolizaComp from './SecondPolizaComp';
const PolizaExecute = () => {
  const {selectedLanguage} = useLangContext()
  const text = productTexts[selectedLanguage];
  const digitalText = digitalSurety[selectedLanguage];
  

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
    <div className='digital-background'>
    <div className='digital-content'>
    <div className='digital-box'>   
    <ProductMenu category1={text.productMenu.category1} category2={text.productMenu.category2} category3={text.productMenu.category3} category4={text.productMenu.category4} 
            category5={text.productMenu.category5} category6={text.productMenu.category6} category7={text.productMenu.category7} category8={text.productMenu.category8} category9={text.productMenu.category9} 
            ejecutarClass={selectedLanguage === "EN" ? "display-none" : ({isActive})=>isActive  ? 'menu-link-selected' :'menu-link'} />

<div className='digital-column'>
    <h5 className='digital-title'>{digitalText.title}</h5>
    
    <SecondPolizaComp />
</div>
      </div>
    </div>
    <img src="/img/digital1.jpg" alt="" className={selectedLanguage === "ES"? 'display-none':'forms-photo'} />
    <div className="push"></div>
    </div>
  )
}

export default PolizaExecute

