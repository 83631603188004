import axios from 'axios'

export const privatePOST = async (path, body) => {
    try {
      const response = await axios.post(path, body)
      return response.data
    } catch (error) {
       return {
        error: true
       }
    }
  }