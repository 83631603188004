import { useEffect, useState } from "react"
import { privatePOST } from '../../../Services/Services';
const {REACT_APP_ENDPOINT}= process.env

export function useSchool(infoInput) {
    const [enroledSchoolAdded, setEnroledSchoolAdded] = useState(false);

    useEffect(() => {
        if(infoInput.isValid) {
            privatePOST(`${REACT_APP_ENDPOINT}public-api/school`, infoInput).then(r => {
                setEnroledSchoolAdded(r);
            })
        }
    }, [infoInput])

    return enroledSchoolAdded
}