import { useEffect, useState } from "react"
import { privatePOST } from '../../../Services/Services';
const {REACT_APP_ENDPOINT}= process.env

export function useCv(candidateInfo) {
    const [candidateAdded, setCandidateAdded] = useState(false);

    useEffect(() => {
        if(candidateInfo.isValid) {
            privatePOST(`${REACT_APP_ENDPOINT}public-api/cv`, candidateInfo).then(r => {
                setCandidateAdded(r);
            })
        }
    }, [candidateInfo])

    return candidateAdded
}