import React from 'react'

const CourseTitle = ({date, title, className, state}) => {
  return (
    <div className={className}>
      <div className='course-top'>  
        <p className='course-date'>{date}</p> <p className='course-state'>{state}</p>
      </div>
        <h2>{title}</h2>
    </div>
  )
}

export default CourseTitle