import { useEffect, useState } from "react"
import { privatePOST } from '../../../Services/Services';
const {REACT_APP_ENDPOINT}= process.env

export function useRent(allRentData) {
    const [rentDataAdded, setRentDataAdded] = useState(false);

    useEffect(() => {
        if(allRentData.isValid) {
            privatePOST(`${REACT_APP_ENDPOINT}public-api/rent`, allRentData).then(r => {
                setRentDataAdded(r);
            })
        }
    }, [allRentData])

    return rentDataAdded
}