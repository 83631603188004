import { useEffect, useState } from "react"
import { privatePOST } from '../../../Services/Services';
const {REACT_APP_ENDPOINT}= process.env

export function useContact(contactInfo) {
    const [contactAdded, setContactAdded] = useState(false);

    useEffect(() => {
        if(contactInfo.isValid) {
            privatePOST(`${REACT_APP_ENDPOINT}public-api/contact`, contactInfo).then(r => {
                setContactAdded(r);
            })
        }
    }, [contactInfo])

    return contactAdded
}