import React from 'react'

const DescargarItem = ({title}) => {
  return (
    <div className='grey-item'>
    <p>{title}</p>
  </div>
  )
}

export default DescargarItem