import React from "react";
import { useEffect } from "react";
import "./lavado.css";
import lavadoText from "../utils/Texts/lavadoTexts.json";
import { useLangContext } from "../../Context/LangContext";
import downloadFormsText from "../utils/Texts/downloadFormsTexts.json";
import DescargarItemComplex from "../utils/DescargarItem/DescargarItemComplex";

const Lavado = () => {
  const { selectedLanguage } = useLangContext();
  const text = lavadoText[selectedLanguage];
  const textDownload = downloadFormsText[selectedLanguage];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="fraud-background">
      <div className="digital-content">
        <div className="digital-box">
          <div className="fraud-title">
            <h5 className="digital-title">
              {text.title}
            </h5>
          </div>
          <p className="lavado-text">
            {text.text}
          </p>
          <div className="file-box">
            <DescargarItemComplex
              title={textDownload.cols.item7.title}
              fileDownload={textDownload.cols.item7.fileDownload}
            />
            <DescargarItemComplex
              title={textDownload.cols.item8.title}
              fileDownload={textDownload.cols.item8.fileDownload}
            />
            <DescargarItemComplex
              title={textDownload.cols.item9.title}
              fileDownload={textDownload.cols.item9.fileDownload}
            />
            <DescargarItemComplex
              title={textDownload.cols.item10.title}
              fileDownload={textDownload.cols.item10.fileDownload}
            />
            <DescargarItemComplex
              title={textDownload.cols.item11.title}
              fileDownload={textDownload.cols.item11.fileDownload}
            />
            <DescargarItemComplex
              title={textDownload.cols.item12.title}
              fileDownload={textDownload.cols.item12.fileDownload}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lavado;
