import { useEffect, useState } from "react"
import { privatePOST } from '../../../Services/Services';
const {REACT_APP_ENDPOINT}= process.env

export function useDirectors(allDirectorData) {
    const [directorInfoAdded, setDirectorInfoAdded] = useState(false);

    useEffect(() => {
        if(allDirectorData.isValid) {
            privatePOST(`${REACT_APP_ENDPOINT}public-api/directors`, allDirectorData).then(r => {
                setDirectorInfoAdded(r);
            })
        }
    }, [allDirectorData])

    return directorInfoAdded
}