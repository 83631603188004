import React from 'react'


const TextComponent = ({text}) => {
  return (
    <>
    <p>{text}</p>
</>
  )
}

export default TextComponent