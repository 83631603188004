import React from 'react'

const SecondPolizaComp = () => {
  return (
    <div className='digital-column'> 
          <h6 className='digital-subtitle'>¿Como Ejecutar/Afectar una Garantia?</h6>
          <p className='digital-text'>Para iniciar el reclamo, podrán dirigirse a la compañia mediante carta documento o nota dirigida a la Aseguradora que mencione el Nro. de poliza afectada e indique el incumplimiento del Tomador que da origen al reclamo. En el caso que oportunamente la Aseguradora deba hacer frente al pago del siniestro, en el acuerdo correspondiente se dejará constancia de que se trata de una póliza entregada por medios electrónicos y no se exigirá devolución de original alguno por parte del Asegurado.</p>
          <h6 className='digital-subtitle'>¿Cómo Desafectar/Anular una Póliza Digital?</h6>
          <p className='digital-text'>Al momento de la finalización de tu obligación, deberás ponernos en conocimiento y aportarnos la documentación que acredite que has cumplido. Dependiendo del tipo de póliza que contrataras, las opciones son:</p>
          <h6 className='final-list'>Nota del Asegurado acreditando el cumplimiento de la obligación sin objeciones</h6>
          <h6 className='final-list'>Acta de Recepción Provisoria</h6>
          <h6 className='final-list'>Acta de Recepción Definitiva</h6>
          <h6 className='final-list'>Certificados de Avance</h6>
          <h6 className='final-list' >Remitos de entrega recibidos en forma fehaciente por el Asegurado.</h6> 
          </div>
  )
}

export default SecondPolizaComp